<template>
  <div class="mailto-cta" v-if="data && nameItem">
    <h4>
      <SvgSprite class="icon" symbol="icons-mail-message" size="60" />
      <span v-html="data.label"></span>
    </h4>
    <a
      :href="`mailto:${data.mailto}?subject=Modification fiche ${nameItem}`"
      target="_blank"
      class="button"
    >
      <span>Complète cette fiche</span>
      <SvgSprite symbol="ui-arrow-cta" size="16" />
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MailtoCta',
  props: {
    data: Object,
    nameItem: String,
  } as any, // eslint-disable-line
})
</script>

<style scoped lang="scss">
$x: (
  xxs: 20px,
  l: 30px,
);

$y: (
  xxs: 15px,
  l: 30px,
);

[class*='mailto-cta--'],
.mailto-cta {
  @include fluid(padding-top, $x);
  @include fluid(padding-right, $y);
  @include fluid(padding-bottom, $x);
  @include fluid(padding-left, $y);

  position: relative;
  color: $c-white;
  background-color: $hippie-blue;

  h4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $spacing;
    color: $c-white;

    .icon {
      margin-left: -6px;
      margin-right: 1.6rem;
    }
  }

  p {
    margin-bottom: $spacing;
    font-size: 1.4rem;

    @include mq(m) {
      font-size: 1.6rem;
    }
  }

  .button {
    @include fira-bold;

    padding: 1.45rem;
    display: inline-flex;
    align-items: center;
    font-size: 1.4rem;
    color: $c-white;
    background: $regal-blue;
    border: 0;
    cursor: pointer;

    svg {
      margin-left: $spacing;
    }

    &:hover {
      opacity: 0.8;
    }

    @include mq(m) {
      font-size: 1.8rem;
    }
  }
}
</style>
