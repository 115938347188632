
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MailtoCta',
  props: {
    data: Object,
    nameItem: String,
  } as any, // eslint-disable-line
})
